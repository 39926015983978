import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";

// Internal
import Container from "@/components/Container";
import EasTable from "./__components/EasTable";
import { IAttendance } from "@/types/attendance-sheet.types";
import { useUrlParams } from "@/hooks/useUrlParams";
import { useGetAttendanceList } from "@/api/attendance";
import { useGetProviderDetails } from "@/api/provider";
import GeneralErrorState from "@/components/GeneralErrorState";

// Antd
import { Spin } from "antd";

// MISC
import dayjs from "dayjs";

export default function Home() {
  // Search params
  const [searchParams] = useSearchParams();
  const endQueryParam = searchParams.get("end") || "";

  const { familyId, providerId, isProviderView, startDate, endDate } =
    useUrlParams({
      searchParams,
    });

  // Fetch/pull from cache... the attendance list
  const {
    isLoading,
    data: attendanceSheets,
    isError: attendanceSheetsErrored,
    error: attendanceSheetsError,
  } = useGetAttendanceList({
    familyId,
    providerId,
    startDate,
    endDate: !endQueryParam
      ? null
      : isProviderView
        ? dayjs(endQueryParam).endOf("month").format("YYYY-MM-DD")
        : endDate,
  });

  // Fetch/pull from cache... the provider info
  const { isLoading: providerInfoLoading, data: providerInfo } =
    useGetProviderDetails(providerId);

  // Reporting user ID to New Relic
  const newRelicIntervalSet = useRef(false);
  useEffect(() => {
    let attemptCount = 5;
    if (newRelicIntervalSet.current === false && (providerId || familyId)) {
      newRelicIntervalSet.current = true;

      const interval = setInterval(() => {
        if (attemptCount <= 0) {
          clearInterval(interval);
        }

        // @ts-expect-error - newrelic would not be defined in TS window definition
        if (typeof window !== "undefined" && window?.newrelic) {
          console.log(
            "report",
            providerId ? `provider-${providerId}` : `family-${familyId}`
          );

          // @ts-expect-error - newrelic would not be defined in TS window definition
          window?.newrelic?.setUserId?.(
            providerId ? `provider-${providerId}` : `family-${familyId}`
          );

          clearInterval(interval);
        }

        attemptCount--;
      }, 2000);
    }
  }, [familyId, newRelicIntervalSet, providerId]);

  if (attendanceSheetsErrored) {
    return <GeneralErrorState error={attendanceSheetsError} />;
  }

  return (
    <>
      {isProviderView && (
        <Spin spinning={providerInfoLoading}>
          <div className="bg-primary bg-opacity-5 text-center py-12 border-b border-[rgba(0,0,0,0.05)] border-solid">
            <h3 className="text-3xl font-semibold tracking-normal">
              {providerInfo?.name}
            </h3>

            <p className="text-sm block mx-auto gap-3 items-center mt-4">
              {[
                providerInfo?.address?.street,
                providerInfo?.address?.city,
                providerInfo?.address?.state,
                providerInfo?.address?.postalCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </p>

            <p className="text-sm inline-flex mx-auto gap-3 items-center mt-1">
              <span>
                <b className="font-semibold">Provider ID:</b> {providerInfo?.id}
              </span>
              <span className="opacity-40">⦁</span>
              {providerInfo?.phoneNumber}
            </p>
          </div>
        </Spin>
      )}

      <Spin spinning={isLoading}>
        <Container>
          <EasTable
            loading={isLoading}
            data={attendanceSheets as IAttendance[]}
          />
        </Container>
      </Spin>
    </>
  );
}
